.links {
    display: inline-grid;
    width: 30%;
    height: 100px;
    column-gap: 0px;
    grid-template-columns: auto auto auto auto auto;
    align-content: center;
    text-align: center;

}

.header {
    display: flex;
    position: absolute;
    width: 100%;
    justify-content: right;
    z-index: 3;
}

.align {}

.svg {
    margin: 5px;
    padding: 3px;
    width: 20px;
    height: 20px;
    fill: white;
    background-size: 200% 200%;
    background-image: linear-gradient(to top, white 50%, transparent 50%);
    -webkit-transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
    -moz-transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
    -ms-transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
    -o-transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
    transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
}

.svg:hover {
    fill: black;
    background-image: linear-gradient(to top, white 51%, transparent 50%);
    background-position: 0 100%;
    -webkit-transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
    -moz-transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
    -ms-transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
    -o-transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
    transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
}

.connect {
    transition: 300ms ease-in;

    align-items: center;
    justify-content: center;
    display: flex;
    margin: 5px;
    width: 100px;
    height: 42px;
    background-color: black;
    border-radius: 5px;
}

.connect:hover {

    transition: 300ms ease-in;
    background-color: white;
    color: black;
    /* background: linear-gradient(to bottom, #cfc09f 22%, #634f2c 24%, #cfc09f 26%, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}

/*  display: 'block', marginLeft: 'auto', marginRight: 'auto', */
.headerImg {
    display: block;
    margin-left: 50%;
    width: 1000px;
    transform: translateX(-50%);
}



@media (max-width:700px) {
    .headerImg {
        width: 800px;
        margin-left: 50%;
        transform: translateX(-50%);
    }

}