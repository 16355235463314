@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Monofett&display=swap);
body {
  margin: 0;
  font-family: 'Rubik';
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;

}



a {
  font-size: 14px;
  font-style: italic;
}

a:visited {
  color: inherit;
}

a:hover {
  color: gray;
  cursor: pointer;
}

html {
  scroll-behavior: smooth;
  /* background-color: black; */
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Minter {
  max-width: 80vh;
  max-height: 80vh;
  padding: 60px 100px;
  margin: 0 auto;
  text-align: left;

}

input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  font-size: 16px;
  width: 100%;
  border-color: rgba(4, 4, 5, 0.1);
  line-height: 32px;
}

input:focus {
  outline: none;
  border-color: rgba(4, 4, 5, 0.4);
}


.btn {
  padding: 9px 16px;
  max-height: 40px;
  /*box-shadow: 4px 4px 15px 0 rgb(0 0 0 / 20%);*/
  border-color: #254cdd;
  color: #254cdd;
  background-color: white;
  border-radius: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

button {
  padding: 9px 16px;
  max-height: 40px;
  /*box-shadow: 4px 4px 15px 0 rgb(0 0 0 / 20%);*/
  border-color: #254cdd;
  color: #254cdd;
  background-color: white;
  border-radius: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

button:hover {
  margin-top: -1px;
}

#title {
  padding-top: 32px;
}

.uploadFile {
  border: 4px dashed rgba(4, 4, 5, 0.1);
  border-radius: 16px;
  text-align: center;
  padding: 32px 60px;
}

#walletButton {
  text-align: right;
  float: right;
  margin-bottom: 50px;
  line-height: 16px;
}

/*
#walletButton:focus {
  outline:none;
  border: none;
}
#walletButton:hover {
  outline:none;
  border :none;
} */

#mintButton {
  margin-top: 40px;
  background-color: #254cdd;
  color: white;
}

/* https://alvarotrigo.com/blog/css-animations-scroll/ */
.content {
  display: block;
  width: min(15vw, 400px);
  transform: translateY(100px);
  opacity: 0;
  transition: 0.5s all ease;
}


.content.active {
  transform: translateY(0);
  opacity: 1;
}

span {
  top: 14px;
  display: inline-block;
  position: relative;
  padding: 0.2em 0;
  overflow: hidden;
}

/* Fade in */
span::after {
  content: '';
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: red;
  opacity: 0;
  transition: opacity 500ms, transform 500ms;
  opacity: 1;
  transform: translate3d(-100%, 0, 0);
}


span.active::after {
  opacity: 1;
  transform: translate3d(0, 0.2em, 0);
  transform: translate3d(0, 0, 0);
}

.row {
  text-align: center;
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%)
  }

  to {
    opacity: 1;
  }
}


@keyframes fadeInTop {
  from {
    opacity: 0;
    transform: translateY(-100%)
  }

  to {
    opacity: 1;
  }
}

.iconContainer {
  display: block;
  position: relative;
  width: 125px;
  height: 125px;
  /* margin-left: 10px; */
  /* margin-right: 10px; */

}

.iconContainer img {
  left: 0;
  transition: opacity 0.5s ease-in-out;
}

.iconContainer .iconTop:hover {
  opacity: 0;
}

.roadmapIcons {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  display: inline-block;
}

.triangle {
  transition: transform 0.5s ease-in-out;
}

.roadmapText {
  position: absolute;
  left: 0;
  right: 0;
  transition: opacity 0.5s ease-in-out;
}
.header_links__3nHEu {
    display: inline-grid;
    width: 30%;
    height: 100px;
    grid-column-gap: 0px;
    column-gap: 0px;
    grid-template-columns: auto auto auto auto auto;
    align-content: center;
    text-align: center;

}

.header_header__2c7h- {
    display: flex;
    position: absolute;
    width: 100%;
    justify-content: right;
    z-index: 3;
}

.header_align__QAICd {}

.header_svg__15uPi {
    margin: 5px;
    padding: 3px;
    width: 20px;
    height: 20px;
    fill: white;
    background-size: 200% 200%;
    background-image: linear-gradient(to top, white 50%, transparent 50%);
    transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
}

.header_svg__15uPi:hover {
    fill: black;
    background-image: linear-gradient(to top, white 51%, transparent 50%);
    background-position: 0 100%;
    transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
}

.header_connect__2hCPD {
    transition: 300ms ease-in;

    align-items: center;
    justify-content: center;
    display: flex;
    margin: 5px;
    width: 100px;
    height: 42px;
    background-color: black;
    border-radius: 5px;
}

.header_connect__2hCPD:hover {

    transition: 300ms ease-in;
    background-color: white;
    color: black;
    /* background: linear-gradient(to bottom, #cfc09f 22%, #634f2c 24%, #cfc09f 26%, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}

/*  display: 'block', marginLeft: 'auto', marginRight: 'auto', */
.header_headerImg__p5f0n {
    display: block;
    margin-left: 50%;
    width: 1000px;
    transform: translateX(-50%);
}



@media (max-width:700px) {
    .header_headerImg__p5f0n {
        width: 800px;
        margin-left: 50%;
        transform: translateX(-50%);
    }

}
